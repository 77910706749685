import React, { Component } from "react";
import "./Main.css";
import Games from "../components/game/Games";
import GamePresentation from "../components/game/GamePresentation";
import ApplicationEvent from "../../ApplicationEvent";
import ContactPage from "../components/contact/ContactPage";
import AboutPage from "../components/about/AboutPage";
import black_block from "../../assets/black_block.png";
import six_box from "../../assets/six_box.png";
import template from "../../assets/template.png";

const items = [
    { "id": 1, "name": "Black Block", image: black_block },
    { "id": 2, "name": "Six Box", image: six_box }//,
    // { "id": 3, "name": "Coming soon", image: template },
    // { "id": 4, "name": "Coming soon", image: template }
];

export default class Main extends Component {

    state = {
        gameItem: null,
        page: 0
    };

    get page() { return this.props.page || 0; }
    get gameItem() { return this.state.gameItem; }
    get eventDispatcher() { return this.props.eventDispatcher; }

    componentDidMount() {
        this.subscribe();
    }
    componentWillUnmount() {
        this.unsubscribe();
    }

    subscribe() {
        if ( this.subscribed ) return;
        this.subscribed = true;
        this.eventDispatcher.addEventListener( ApplicationEvent.MAIN_PAGE, this.onBack, this );
        this.eventDispatcher.addEventListener( ApplicationEvent.CONTACT_PAGE, this.onContact, this );
        this.eventDispatcher.addEventListener( ApplicationEvent.ABOUT_PAGE, this.onAbout, this );
        this.eventDispatcher.addEventListener( ApplicationEvent.ITEM, this.onItem, this );
    }
    unsubscribe() {
        if ( !this.subscribed ) return;
        this.subscribed = true;
        this.eventDispatcher.removeEventListener( ApplicationEvent.MAIN_PAGE, this.onBack, this );
        this.eventDispatcher.removeEventListener( ApplicationEvent.CONTACT_PAGE, this.onContact, this );
        this.eventDispatcher.removeEventListener( ApplicationEvent.ABOUT_PAGE, this.onAbout, this );
        this.eventDispatcher.removeEventListener( ApplicationEvent.ITEM, this.onItem, this );
    }

    onBack() {
        this.setState( { page: 0 } );
    }
    onContact() {
        this.setState( { page: 1 } );
    }
    onAbout() {
        this.setState( { page: 2 } );
    }
    onItem( event ) {
        this.setState( { page: 3, gameItem: event.data.item } );
    }

    getContent() {

        const page = this.props.page || this.state.page;

        switch( page ) {
            case 1:
                return <ContactPage eventDispatcher={ this.eventDispatcher } />;

            case 2:
                return <AboutPage eventDispatcher={ this.eventDispatcher } />;

            case 3:
                return <GamePresentation gameItem={ this.getGameItem() } eventDispatcher={ this.eventDispatcher } />;

            default:
                return <Games items={ items } eventDispatcher={ this.eventDispatcher } />;

        }

    }

    getGameItem() {
        if ( this.getItem() )
            return this.getItem();

        return this.gameItem;
    }
    getID() {
        let id = 0;
        if ( this.props.search ) {
            this.props.search.map( searchItem => {
                if ( searchItem.name === "id" )
                    id = searchItem.value;
            } );
        }
        return id;
    }
    getItem() {
        const ID = this.getID();
        let result = null;
        items.map( item => {
            if ( item.id === parseInt( ID ) )
                result = item;
        } );
        return result;
    }

    render() {
        return (
            <div className="main">
                <div className="main-container">
                    { this.getContent() }
                </div>
            </div>
        );
    }

}