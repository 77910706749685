
import React, { Component } from "react";
import "./GamePresentation.css";

export default class GamePresentation extends Component {

    get gameID() { return this.props.gameItem ? this.props.gameItem.id : 0; }
    get item() { return this.props.gameItem; }

    buttonDivGet() {
        if ( this.item.id !== 1 ) {
            return <div className="soon-block">COMING SOON</div>
        }

        return <a href="./collision" className="game-play-button red">Play</a>;
    }

    descriptionDivGet() {
        switch( this.gameID ) {
            case 1:
                return <div><div className="game-description">Play the <b>Black Block</b>.</div><div className="game-description">Raise the score. In the game Black Block you arrange the blocks from the panel for blocks so as to fill the lines. All filled bouladut lines are destroyed. For their destruction, you will receive player level growth points. The more lines you destroy in one block, the more points you get. Great difficulty open up with the growth of your level. The more skillfully you fill the field, the greater the complexity of the game you can choose.</div><div className="game-description">The game was created for leisure activities, the development of logical and coordination thinking.</div><div className="game-description">Have fun! :)</div></div>;
            case 2:
                return <div><div className="game-description">The game <b>Six Box</b> is under construction now.</div><div className="game-description">A little time will pass and she will be ready! ;)</div></div>;
            default:
                return <div><div className="game-description">There is not a game :(</div></div>;
        }
    }

    render() {

        clearTimeout( this.sharethisTimeout );
        this.sharethisTimeout = setTimeout( () => {
            if ( !window.__sharethis__.config ) return;
            window.__sharethis__.load('inline-share-buttons', window.__sharethis__.config[ "inline-share-buttons" ] );
        }, 10);

        return (
            <div className="game-presentation">
                <div className="game-image-container">
                    <img className="game-image" src={ this.item.image } alt="Game" />
                </div>
                <div className="game-description-container">
                    { this.buttonDivGet() }
                    { this.descriptionDivGet() } 
                    <div className="game-share-container">
                        <div className="sharethis-inline-share-buttons"></div>
                    </div>
                </div>
            </div>
        );
    }

}