import Event from "./event/Event";

export default class ApplicationEvent extends Event {

    constructor( type, component = null, data = {} ) {

        super( type );

        this.component = component;
        this.data = data;

    }

}

ApplicationEvent.MAIN_PAGE = "applicationMainPage";
ApplicationEvent.CONTACT_PAGE = "applicationContactPage";
ApplicationEvent.ABOUT_PAGE = "applicationAboutPage";
ApplicationEvent.ITEM = "applicationItem";