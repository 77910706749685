import React, { Component } from "react";
import "./AboutPage.css";

export default class AboutPage extends Component {

    get item() { return this.props.gameItem; }

    render() {

        return (
            <div className="about">
                <div className="about-text">
                    Our contacts: <a className="about-email" href="mailto:admin@2ggames.net">admin@2ggames.net</a>
                </div>
                <div className="about-text">
                    © 2020 2G Games.
                </div>
            </div>
        );

    }

}