import React from 'react';
import './App.css';
import Site from './site/Site';
import './site/Site.css';
import EventDispathcer from './event/EventDispatcher';

const eventDispatcher = new EventDispathcer();

function App() {
	return (
		<div className="app">
			<Site className="site" eventDispatcher={ eventDispatcher } />
		</div>
	);
}

export default App;
