import React, { Component } from "react";
import "./Games.css";
import GameItem from "./GameItem";
import ApplicationEvent from "../../../ApplicationEvent";

export default class Games extends Component {

    get eventDispatcher() { return this.props.eventDispatcher; }

    componentDidMount() {
        this.subscribe();
    }
    componentWillUnmount() {
        this.unsubscribe();
    }

    subscribe() {
        if ( this.subscribed ) return;
        this.subscribed = true;
    }
    unsubscribe() {
        if ( !this.subscribed ) return;
        this.subscribed = true;
    }

    onGameItemClick( item ) {
        window.location.href = `./?page=game&id=${item.id}`;
        // this.eventDispatcher.dispatchEvent( new ApplicationEvent( ApplicationEvent.ITEM, this, { item } ) );
    } 

    generateGames() {
        return this.props.items.map( item => {
            return <GameItem key={ item.id } item={ item } onClick={ this.onGameItemClick.bind( this ) } />
        } );
    }

    render() {
        return (
            <div className="games">
                { this.generateGames() }
            </div>
        );
    }

}