import React, { Component } from "react";
import "./BottomPanel.css";

export default class BottomPanel extends Component {

    get item() { return this.props.gameItem; }

    render() {

        return (
            <div className="bottom-panel">
                <a className="bottom-panel-text" href="./">© 2020 2G Games</a> 
                {/* <div className="bottom-container">
                    
                </div> */}
            </div>
        );

    }

}