import React, { Component } from "react";
import TopPanel from "./components/toppanel/TopPanel";
import Main from "./main/Main";
import BottomPanel from "./components/bottom/BottomPanel";
// import { BrowserRouter, Link } from "react-router-dom";

export default class Site extends Component {

    get eventDispatcher() { return this.props.eventDispatcher; }

    getPage() {
        const pageObject = this.getSearchObjectByName( "page" );
        let page = window.location.pathname && window.location.pathname.length > 0 ?
            window.location.pathname.substring( 1, window.location.pathname.length ) : null;
        if ( pageObject ) page = pageObject.value;

        switch( page ) {
            case "contact":
                return 1;
            case "about":
                return 2;
            case "game":
                return 3;
            default:
                return 0;
        }
        return 0;
    }

    getSearch() {
        const search = window.location.search && window.location.search.indexOf( "?" ) >= 0 ? 
            window.location.search.substring( 1, window.location.search.length ) : "";
        const split = search.split( "&" );
        const list = [];
        split.map( item => {
            const a = item.split( "=" );
            list.push( { name: a[ 0 ], value: a[ 1 ] } );
        } );
        return list;
    }
    getSearchObjectByName( name ) {
        let object = null;
        const search = this.getSearch(); 
        if ( search ) {
            search.map( searchItem => {
                if ( searchItem.name === name )
                    object = searchItem;
            } );
        }
        return object;
    }

    render() {
        return (
            <div>
                <TopPanel eventDispatcher={ this.eventDispatcher } />
                <Main page={ this.getPage() } search={ this.getSearch() } eventDispatcher={ this.eventDispatcher } />
                <BottomPanel />
            </div>
        );
    }

}