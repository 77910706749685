import React, { Component } from "react";
import line from "../../../assets/line.png";
import circle from "../../../assets/head_tape_logo_circle.png";
import logo from "../../../assets/logo.png";
import './TopPanel.css';
import ApplicationEvent from "../../../ApplicationEvent";

export default class TopPanel extends Component {

    get eventDispatcher() { return this.props.eventDispatcher; }

    componentDidMount() {
        this.subscribe();
    }
    componentWillUnmount() {
        this.unsubscribe();
    }

    subscribe() {
        if ( this.subscribed ) return;
        this.subscribed = true;
    }
    unsubscribe() {
        if ( !this.subscribed ) return;
        this.subscribed = true;
    }

    onBackClick() {
        // this.dispatch( ApplicationEvent.MAIN_PAGE );
        window.location.href = "./";
    }
    onGames() {
        // this.dispatch( ApplicationEvent.MAIN_PAGE );
        window.location.href = "./";
    }
    onContact() {
        // this.dispatch( ApplicationEvent.CONTACT_PAGE );
        window.location.href = "./?page=contact";
    }
    onAbout() {
        // this.dispatch( ApplicationEvent.ABOUT_PAGE );
        window.location.href = "./?page=about";
    }
    dispatch( eventName ) {
        const eventInstance = new ApplicationEvent( eventName, this );
        this.eventDispatcher.dispatchEvent( eventInstance );
    }

    render() {
        return <div className="top-panel">
            <div className="top-panel-container">
                <div className="panel-line-container">
                    <img className="panel-circle" src={circle} alt="Circle" />
                    <img className="panel-line" src={line} alt="Line" />
                    <img className="panel-logo" src={logo} alt="Logo" />
                    <div className="panel-hit-area-back" onClick={ this.onBackClick.bind( this ) } ></div>
                </div>
                <div className="panel-title-container">
                    <div className="panel-menu-container">
                        <span onClick={ this.onGames.bind( this ) } className="panel-menu">Games</span>
                        <span onClick={ this.onContact.bind( this ) } className="panel-menu">Contact</span>
                        <span onClick={ this.onAbout.bind( this ) } className="panel-menu">About</span>
                    </div>
                </div>
            </div>
        </div>;
    }

} 