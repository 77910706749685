import React, { Component } from "react";
import "./ContactPage.css";

export default class ContactPage extends Component {

    state = {
        sended: false
    }

    onClick( event ) {
        const nameElement = document.getElementById( "name" );
        const emailElement = document.getElementById( "email" );
        const messageElement = document.getElementById( "message" );

        if ( nameElement.value.length >= 3 && emailElement.value.length >= 5 && messageElement.value.length >= 20 ) {

            nameElement.disabled = true;
            emailElement.disabled = true;
            messageElement.disabled = true;

            // this.sendAsGet( nameElement.value, emailElement.value, messageElement.value );
            this.sendAsPost( nameElement.value, emailElement.value, messageElement.value );

            this.setState( { sended: true } );
        }
    }

    sendAsGet( name, email, message ) {
        const data = { name, email, message };

        const xhr = new XMLHttpRequest();
        xhr.open( "GET", "./contact/contact.php" + this.formatParams( data ) );
        xhr.onload = function ( event ) {
            console.log( event );
        };
        xhr.send();
    }
    
    sendAsPost( name, email, message ) {
        const data = new FormData();
        data.append( "name", name );
        data.append( "email", email );
        data.append( "message", message );

        const xhr = new XMLHttpRequest();
        xhr.open( "POST", "./contact/contact.php" );
        xhr.onload = function ( event ) {
            console.log( event );
        };
        xhr.send( data );
    }

    formatParams( params ) {
        return "?" + Object.keys( params )
            .map( function( key ) {
                return key + "=" + encodeURIComponent( params[ key ] )
            } )
            .join("&");
    }

    buttonGet() {
        if ( this.state.sended) {
            return <div className="contact-description contact-sended">Sended</div>;
        }

        return <a className="contact-button red" onClick={ this.onClick.bind( this ) }>Send</a>;
    }

    render() {

        return (
            <div className="contact">
                <div className="contact-description">
                    If you have any questions, you can leave them here.
                </div>
                <input id="name" type="name" placeholder="What's your name?" maxLength="60" tabIndex="1" className="contact-input"></input>
                <input id="email" type="email" placeholder="What's your email?" maxLength="100" tabIndex="2" className="contact-input"></input>
                <textarea id="message" type="message" placeholder="Tell something for as" maxLength="1000" tabIndex="3" className="contact-input-message contact-input"></textarea>
                { this.buttonGet() }
                {/* <a className="contact-button red" onClick={ this.onClick.bind( this ) }>Send</a> */}
            </div>
        );

    }

}