import React, { Component } from "react";
import "./GameItem.css";
import shadow from "../../../assets/shadow.png";

export default class GameItem extends Component {

    get item() { return this.props.item; }

    onClick( event ) {
        if ( !this.props.onClick ) return;
        this.props.onClick( this.item );
    }

    render() {
        return (
            <div onClick={ this.onClick.bind( this ) } className="game-item">
                {/* <img className="game-shadow" src={ shadow } /> */}
                <img className="game-image" src={ this.item.image } alt="Game" />
            </div>
        );
    }

}